'use strict';
import React from 'react';

class LeagueRankingFilters extends React.Component {
    render() {
        const labels = this.props.labels;
        const sportLabels = this.props.sportLabels;

        return (
            <React.StrictMode>
                <div className="flex justify-between align-items-center full-width margin-bottom-20">
                    <div class="league-name without-bar">{labels.ranking}</div>

                    <div className="order flex align-items-center justify-content-end">
                        <label className={"inline-flex margin-right-20 margin-right-lg-40 align-items-center"} for="guest_players">
                            {labels.guest_players}
                            <input id="guest_players" type="checkbox" value="true" onChange={this.props.includeGuest} className={"form-check margin-left-5 margin-left-lg-10"}/>
                        </label>
                        <label className={"inline-flex"} for="order">
                            {labels.order_by}:
                            <select name="order" id="order" className="select-simple" onChange={this.props.orderRanking}>
                                {this.props.orders.map((el) => (
                                    <>
                                        {(el !== 'positive_score' && el !== 'negative_score') && <option value={el}>{labels.orders[el]}</option>}
                                        {(el === 'positive_score' || el === 'negative_score') && <option value={el}>{sportLabels[el]}</option>}
                                    </>
                                ))}
                            </select>
                        </label>
                    </div>
                </div>
            </React.StrictMode>
        );
    }
}

export default LeagueRankingFilters;
